import React from 'react'
import { Link } from 'gatsby'
import { graphql } from 'gatsby'
import Layout from '../components/layout'
import DigestCard from '../components/digestCard'
import styles from '../styles/index.module.css'
import IconWeatherReport from '../assets/icon-weather-report.svg'
import IconStocks from '../assets/icon-stocks.svg'
import IconOurNetwork from '../assets/icon-our-network.svg'
import RadX from '../assets/radx-radical.svg'
import Slack from '../assets/slack.svg'
import FDA from '../assets/fda.svg'

const Index = ({ data }) => {

	return (
		<Layout>
			<div className={styles.cards}>
			<Link to={'weatherReport'} className={styles.card}>
					<IconWeatherReport className={styles.icon}/>
					<h4>COVID-19 Weather Report</h4>
				</Link>
				<Link to={'stock'} className={styles.card}>
					<IconStocks className={styles.icon}/>
					<h4>Request stocks</h4>
				</Link>
				<Link to={'network'} className={styles.card}>
					<IconOurNetwork className={styles.icon}/>
					<h4>Ask our network</h4>
				</Link>
				{/*<Link to={'project'} className={styles.card}>*/}
				{/*	<IconOurNetwork className={styles.icon}/>*/}
				{/*	<h4>Award Publications</h4>*/}
				{/*</Link>*/}
				<a className={styles.channel} href={'https://www.radxrad.org/'} target='_blank' rel='noreferrer'>
					<RadX/>
					<span>Back to DCC portal</span>
				</a>
				<a className={styles.channel} href={'https://join.slack.com/t/radx-rad/shared_invite/zt-pyz3ovmv-x45EORUUOAZ09KXQs5klxA'} target='_blank' rel='noreferrer'>
					<Slack/>
					<span>Join/sign in to RADx-Rad</span>
				</a>
				<a className={styles.channel} href={'https://airtable.com/shrTeDEayOwZZ9EqL'} target='_blank' rel='noreferrer'>
					<FDA/>
					<span>Ask the FDA</span>
				</a>
			</div>
			<div className={styles.digests}>
				<h2>Latest digests</h2>
				{
					data.digests.nodes.filter(d => ["Highlights from NIH RADx initiatives", "Updates from CDC, FDA and Other Agencies", "NIH Guidance"].includes(d.data.Title) ) .map(digest => {
						const {data, id} = digest
						return (
							<DigestCard key={id} title={data.Title} image={data.Image?.[0].url} topics={data.Topics} publications={data.News} dateUpdated={data.Date_updated} slug={data.Slug}/>
						)
					})
				}
			</div>
		</Layout>
	)
}

export const query = graphql`
    query {
		digests: allAirtableDigests(sort: {fields: data___Date_updated, order: DESC}) {
			nodes {
				id
				data {
					Date_updated
					Image {
						url
					}
					News {
						data {
							Title
							Source
							Date
							Source_type
              Author
              Journal
						}
					}
					Title
					Topics
					Slug
				}
			}
		}
    }
`

export default Index
